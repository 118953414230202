import React, { Component } from 'react'
import { Button } from '.'

class NetlifyForm extends Component {
  // constructor(props) {
  //   super(props)
  //   this.handleSubmit = this.handleSubmit.bind(this)
  // }

  // handleSubmit = e => {
  //   // e.preventDefault()
  //   // const formData = new FormData(e.target)
  //   // formData.append('no-cache', true)
  //   // fetch('/', {
  //   //   method: 'POST',
  //   //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //   //   body: formData,
  //   // })
  //   //   .then(() => {
  //   //     this.props.success || alert('form sent')
  //   //   })
  //   //   .catch(error => {
  //   //     this.props.error || alert(error)
  //   //   })
  // }

  render() {
    const { children, name, className, buttonStyle, buttonText } = this.props
    const formName = name || 'basic-form'
    return (
      <form
        name={formName}
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        // onSubmit={this.handleSubmit}
        className={className}
        action="/?no-cache=1"
      >
        <noscript>activate javascript to use this form</noscript>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value={formName} />
        <input type="hidden" name="no-cache" value={true} />
        {children}
        <Button className={buttonStyle && buttonStyle}>
          {buttonText || 'send'}
        </Button>
      </form>
    )
  }
}

export default NetlifyForm
