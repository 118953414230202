import React from 'react'

import Image from 'gatsby-image'

export { default as NetlifyForm } from './NetlifyForm'
export { default as PageTransition } from './PageTransition'
export { default as SEO } from './SEO'

import GatsbyLink from 'gatsby-link'
import Logo from '../../static/images/Logo'

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import facepaint from 'facepaint'

import {
  HeroStyle,
  SectionStyle,
  ContainerStyle,
  ColumnsStyle,
  ColumnStyle,
  ButtonStyle,
  BreakPoints,
  DefaultPaddingX,
  DefaultPaddingY,
  TitleCaptionStyle,
  rhythm,
} from './utils/style'

export const breakpoints = {
  narrow: ['100%', BreakPoints[0], BreakPoints[0], BreakPoints[0]],
  normal: ['100%', BreakPoints[0], BreakPoints[1], BreakPoints[2]],
  wide: ['100%', '100%', '100%', BreakPoints[2]],
  superWide: ['100%', '100%', '100%', '100%', BreakPoints[3]],
}

export const mediaQuery = BreakPoints.map(bp => `@media (min-width: ${bp})`)

export const mediaQueryGT = {
  mobile: `@media (min-width: ${BreakPoints[0]})`,
  tablet: `@media (min-width: ${BreakPoints[1]})`,
  desktop: `@media (min-width: ${BreakPoints[2]})`,
  wideScreen: `@media (min-width: ${BreakPoints[3]})`,
}
export const mediaQueryLT = {
  mobile: `@media (max-width: ${BreakPoints[0]})`,
  tablet: `@media (max-width: ${BreakPoints[1]})`,
  desktop: `@media (max-width: ${BreakPoints[2]})`,
  wideScreen: `@media (max-width: ${BreakPoints[3]})`,
}

export const mediaQueries = facepaint(
  BreakPoints.map((bp, i) => {
    return `@media (min-width: ${bp})`
  })
)

export const ContainerBreakPoints = props =>
  css(
    mediaQueries({
      width: props.width ? breakpoints[props.width] : breakpoints['normal'],
      padding: `${DefaultPaddingY} ${DefaultPaddingX}`,
      margin: '0 auto',
    })
  )

export const Columns = styled('div')`
  ${ColumnsStyle};
`
export const Column = styled('div')`
  ${ColumnStyle};
`
export const Container = styled.div`
  overflow: visible;
  ${ContainerBreakPoints};
  ${ContainerStyle};
  ${Columns} {
    margin-left: -${DefaultPaddingX};
    margin-right: -${DefaultPaddingX};
  }
`

export const Section = styled.section`
  ${SectionStyle};
  ${props => props.className && props.className};
`

const HeroWrapper = styled.section`
  position: relative;
  ${SectionStyle}
  ${HeroStyle}
  ${props => props.className && props.className};
  & > *{
    z-index: 10;
  }${props =>
    props.overlay &&
    `
    &:after{
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 5;
      ${props.overlay}
    }
  `};
  
`
export const Hero = props => (
  <HeroWrapper {...props}>
    {props.hideLogo || (
      <GatsbyLink
        aria-label="home"
        to="/"
        css={css`
          z-index: 99999;
          position: absolute;
          top: ${rhythm(1 / 2)};
          left: ${rhythm(1 / 2)};
          width: 100px;
          height: auto;
          z-index: 10;
        `}
      >
        <Logo
          alt="logo"
          css={css`
            width: 100%;
            height: 100%;
          `}
        />
      </GatsbyLink>
    )}
    {props.image && (
      <Image
        fluid={props.image}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: 0,
          left: 0,
          zIndex: 1,
        }}
        imgStyle={{ objectPosition: '50% 66%' }}
      />
    )}
    {props.children}
  </HeroWrapper>
)

export const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const TitleCaption = styled('p')`
  ${TitleCaptionStyle};
  ${props => props.className && props.className};
`

export const Footer = styled.footer`
  ${SectionStyle};
  ${props => props.className && props.className};
`

export const Button = styled('button')`
  ${ButtonStyle};
`
const ButtonA = styled('a')`
  ${ButtonStyle};
`
const ButtonGatsbyLink = styled(GatsbyLink)`
  ${ButtonStyle};
`

export const LinkButton = props => {
  if (props.to) {
    return <ButtonGatsbyLink {...props} />
  } else {
    return <ButtonA {...props} />
  }
}
