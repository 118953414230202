import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors, fonts } from '../webhart-base/utils/style'

const InputStyle = css`
  background: rgba(0, 0, 0, 0.1);
  border: none;
  color: ${colors.orange};
  text-align: left;
  padding-left: 0.5ch;
  line-height: 1.2;
  font-family: ${fonts.mono};
`

const StyledInput = styled.input`
  ${InputStyle};
  width: 1ch;
  max-width: 100%;
  width: ${props => (props.length ? `${props.length + 1}ch` : `2ch`)};
`

const StyledTextarea = styled.textarea`
  ${InputStyle};
  width: 100%;
  height: 160px;
  resize: vertical;
`

class FluidInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      length: 0,
    }
  }
  render() {
    const { length: stateLength } = this.state
    const { type, required } = this.props
    const baseLength =
      (this.props.placeholder && this.props.placeholder.length) || 0

    const length = stateLength == 0 ? baseLength : stateLength

    return (
      <>
        {type == 'textarea' ? (
          <StyledTextarea {...this.props} />
        ) : (
          <StyledInput
            {...this.props}
            length={length}
            onChange={e => this.setState({ length: e.target.value.length })}
          />
        )}
        {required && (
          <span
            css={css`
              color: ${colors.orange};
              vertical-align: super;
              position: absolute;
              margin: -0.5ch;
              /* font-size: 50%; */
            `}
          >
            *
          </span>
        )}
      </>
    )
  }
}

export default FluidInput
