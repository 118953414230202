const base = {
  name: `WEB hart`,
  shortName: `webhart`,
  tagline: `Tailor made websites`,
  // url: `https://webhartfinal.netlify.com`, // no trailing slash!
  url: `https://web-hart.com`,
  description: `creating high quality websites tailored to your needs. In every project we keep the same clear goals in mind: speed, security, SEO, and usability across all devices.`,
  primaryColor: '#FF8C00',
  primaryBgColor: '#0F1E28',
}

const config = {
  base: base,
  /* meta tags */
  siteTitle: `${base.tagline}`,
  siteTitlePostfix: ` 🦌 ${base.name}`,
  siteDescription: base.description,
  siteImage: `webhart.png`,
  siteLanguage: `en`,

  /* site header */
  headerTitle: `${base.name}`,
  headerSubTitle: `${base.tagline}`,

  /* url */
  siteUrl: base.url,
  // pathPrefix: ``,
  analyticsID: `UA-19600184-6`,

  manifest: {
    name: base.name,
    short_name: base.shortName,
    start_url: '/',
    background_color: base.primaryBgColor,
    theme_color: base.primaryColor,
    display: 'minimal-ui',
    icon: 'static/images/favicon.png', // This path is relative to the root of the site.
  },
}

module.exports = config
