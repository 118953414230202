import React from 'react'
import { css } from '@emotion/core'
import { transition, rhythm } from '../webhart-base/utils/style'
import { TitleCaption } from '../webhart-base'

const ScrollDown = ({ title, background }) => {
  return (
    <div
      css={css`
        text-align: center;
        padding: ${rhythm(2)} 0 0;
        ${background && `background: ${background};`};
      `}
    >
      <h2>{title ? title : 'Ready to get started?'}</h2>
      <button
        onClick={() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          })
        }}
        css={css`
          position: relative;
          background: none;
          border: none;
          cursor: pointer;
          p {
            position: relative;
            &:after {
              transition: ${transition};
              content: '';
              transform: rotate(45deg);
              position: absolute;
              width: 20px;
              height: 20px;
              top: 100%;
              left: 50%;
              margin-left: -10px;
              border: solid black;
              border-width: 0 2px 2px 0;
            }
          }
          &:hover p:after {
            transform: rotate(45deg) translate(3px, 3px);
          }
        `}
      >
        <TitleCaption>
          Or something else in mind?
          <br />
          get in touch!
        </TitleCaption>
      </button>
    </div>
  )
}

export default ScrollDown
