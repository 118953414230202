import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Link from 'gatsby-link'

import GatsbyLink from 'gatsby-link'
import Logo from '../../static/images/Logo'

import {
  transition,
  rhythm,
  gradients,
  colors,
} from '../webhart-base/utils/style'
import { mediaQueryGT } from '../webhart-base'

const Nav = styled.nav`
  position: fixed;
  background: ${gradients.darkBlue};
  z-index: 999;
  display: block;
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.4);
  top: -100%;
  transition: ${transition};
  text-align: center;

  a {
    display: block;
    color: ${colors.orange};
    text-decoration: none;
    font-weight: 600;
    padding: ${rhythm(1 / 2)};
    font-size: ${rhythm(1)};
    ${mediaQueryGT['tablet']} {
      padding: ${rhythm(1)};
      font-size: ${rhythm(2)};
    }
  }
  ${props =>
    props.active &&
    `
    top: 0;
  `};
`

class Navigation extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { active, itemClick } = this.props
    // const active = true
    return (
      // <Headroom>
      <Nav active={active}>
        <GatsbyLink
          to="/"
          aria-label="home"
          onClick={itemClick}
          css={css`
            z-index: 99999;
          `}
        >
          <Logo
            alt="logo"
            css={css`
              position: absolute;
              top: ${rhythm(1 / 2)};
              left: ${rhythm(1 / 2)};
              width: 100px;
              height: auto;
            `}
          />
        </GatsbyLink>
        <Link to="/" key="nav-home" onClick={itemClick}>
          Home
        </Link>
        <Link to="/portfolio" key="nav-portfolio" onClick={itemClick}>
          Portfolio
        </Link>
        <Link to="/work-together" key="nav-work-together" onClick={itemClick}>
          Work together
        </Link>
        <Link to="/about-me" key="nav-about-me" onClick={itemClick}>
          About me
        </Link>
        <Link to="/contact" key="nav-contact" onClick={itemClick}>
          Contact
        </Link>
      </Nav>
      // </Headroom>
    )
  }
}

export default Navigation
