import config from './site-config'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'

import Typography from 'typography'

export const baseFonts = {
  //import fonts in Layout!!
  serif: ['Roboto slab', 'serif'],
  sansSerif: ['Poppins', 'sans-serif'],
  mono: ['Anonymous Pro', 'mono'],
}
export const fonts = {
  serif: baseFonts.serif.join(', '),
  sansSerif: baseFonts.sansSerif.join(', '),
  mono: baseFonts.mono.join(', '),
  primary: baseFonts.serif.join(', '),
}

export const typography = new Typography({
  baseFontSize: '18px',
  scaleRatio: 4,
  bodyWeight: '400',
  baseLineHeight: 1.5,
  headerFontFamily: baseFonts.sansSerif,
  headerWeight: '600',
  bodyFontFamily: baseFonts.sansSerif,
  overrideThemeStyles: () => ({
    h1: {
      letterSpacing: '1px',
    },
    code: {
      fontFamily: fonts.mono,
      fontWeight: 200,
    },
  }),
})

export const { scale, rhythm, options } = typography

export const DefaultPaddingX = rhythm(1 / 2)
export const DefaultPaddingY = 0

export const BreakPoints = ['600px', '900px', '1200px', '1800px']

export const colors = {
  primary: config.base.primaryColor,
  primaryBG: config.base.primaryBgColor,
  darkBlue: config.base.primaryBgColor,
  blue: '#325F7A',
  lightBlue: '#009EE2',
  orange: '#FF8C00',
}
export const gradients = {
  darkBlue: `linear-gradient(-45deg, ${colors.darkBlue}, ${colors.blue})`,
  orange: `linear-gradient(-45deg, #934F00, ${colors.orange})`,
}

export const sizing = {
  default: '16px',
  large: '18px',
  maxWidth: '540px',
  width: '90vw',
}

export const packages = {
  mtm: 'made to measure',
  mto: 'made to order',
  bspk: 'bespoke',
}

export const transition = '0.2s'

export const animations = {
  /* The typing effect */
  typing: keyframes`
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    `,
  /* The typewriter cursor effect */
  blinkCaret: keyframes`
      from,
      to {
        border-color: transparent;
      }
      50% {
        border-color: ${colors.lightBlue};
      }
    `,
}

export const globalStyle = `
  .Burger {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99999999;
    .BurgerBox div,
    .BurgerBox div::after,
    .BurgerBox div::before {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
}
  box-sizing: border-size;
  *::selection{
    color: ${colors.orange};
    background: ${colors.blue};
  }
  a{
    text-decoration-skip: objects;
    color: inherit;
  }
  i {
    color: ${colors.lightBlue};
  }
`

export const TitleCaptionStyle = props => css`
  font-size: ${rhythm(3 / 4)};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  ${props.color && `color: ${props.color};`};
`

export const ButtonStyle = props => css`
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: ${props.background || 'black'};
  color: ${props.color || 'white'};

  padding: ${rhythm(1 / 3)} ${rhythm(1 / 2)};
  margin: ${rhythm(1 / 2)} ${rhythm(1 / 2)};

  transition: ${transition};
  display: inline-block;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    ${'' /* background: ${props.hoverBackground || 'white'};
    color: ${props.hoverColor || 'black'}; */};
  }
`
export const SectionStyle = props => css`
  ${props.background
    ? `background: ${props.background};`
    : `
      background:#eee; 
      &:nth-child(odd){
        background: whitesmoke;
      }
  `};
  ${props.color && `color:${props.color}`};
  padding: ${rhythm(2)} 0;
`
export const HeroStyle = props => css`
  text-align: center;

  ${props.size
    ? `
      min-height: ${props.size}vh;
      height: ${props.size}%;
    `
    : `
      min-height: 100vh;
      height: 100%;
    `} width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-attachment: fixed;
`
export const ContainerStyle = css`
  text-align: center;
`
export const ColumnsStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
`

export const ColumnStyle = css`
  padding: 0 ${rhythm(1 / 2)};
  margin: ${rhythm(1 / 2)};
  flex: 0 1 250px;
`
