import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'

import { Spin as Hamburger } from 'react-burgers'

import 'typeface-poppins'
import 'typeface-roboto-mono'
import 'typeface-roboto-slab'
import 'typeface-anonymous-pro'

import Nav from './Navigation'

import { globalStyle } from '../webhart-base/utils/style'
import Footer from './Footer'
import { SEO } from '../webhart-base'

const AppWrapper = styled('main')`
  margin-bottom: 100vh;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
`

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = { menuActive: false }
  }
  render() {
    const { menuActive } = this.state
    return (
      <React.Fragment>
        <Global styles={css(globalStyle)} />
        <Helmet key="app-head">
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Helmet>
        <SEO />

        <React.Fragment key="app-content">
          <Hamburger
            name="show menu"
            width={40}
            lineHeight={4}
            lineSpacing={7}
            color="#FFF"
            borderRadius={10}
            padding="40px 30px"
            active={menuActive}
            onClick={() => this.setState({ menuActive: !menuActive })}
          />
          <Nav
            active={menuActive}
            itemClick={() => this.setState({ menuActive: !menuActive })}
          />
          <AppWrapper>{this.props.children}</AppWrapper>
          <Footer />
        </React.Fragment>
      </React.Fragment>
    )
  }
}

// const Layout = ({ element, props }) => (
//   <React.Fragment>
//     <Helmet key="app-head">
//       <meta name="viewport" content="initial-scale=1.0, width=device-width" />
//     </Helmet>
//     <SEO />

//     <React.Fragment key="app-content">
//       <Nav />
//       <AppWrapper {...props}>{element}</AppWrapper>
//       <Footer />
//     </React.Fragment>
//   </React.Fragment>
// )

export default Layout
