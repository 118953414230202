import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import GatsbyLink from 'gatsby-link'

import { Footer, Container, NetlifyForm, Button } from '../webhart-base'
import Input from '../components/Input'
import { colors, gradients, rhythm } from '../webhart-base/utils/style'

const characters = [
  {
    name: 'Master Yoda',
    age: "900'ish",
    website: 'thejediorder.force',
    email: 'master.yoda@thejediorder.force',
    location: 'the star system of Dagobah',
    wish: 'teaching use the force. Do or do not. There is no try.',
  },
  {
    name: 'Darth Vader',
    age: '45',
    website: 'theempire.gov',
    email: 'anakin.skywalker@theempire.gov',
    location: 'the Death Star',
    wish:
      'Using to power of anger to channel the dark side in from within yourself!',
  },
  {
    name: 'Albus Dumbledore',
    age: '115',
    website: 'hogwarts.edu',
    email: 'headmaster@hogwarts.edu',
    location: 'my office',
    wish: 'your acceptance to Hogwarts School of Witchcraft and Wizardry',
  },
  {
    name: 'Gandalf',
    age: 'infinite',
    website: 'youshallnotpass.com',
    email: 'mithrandir@istari.org',
    location: 'Middle Earth',
    wish: 'returning the Ring of Power to the fires of Mount Doom in Mordor',
  },
  {
    name: 'Prince Charming',
    age: '25',
    website: 'princecharming.com',
    email: 'prince@thekingdom.com',
    location: 'A castle',
    wish:
      "Creating a blog where I can write stories about my adventures, the princesses I've saved and men's fashion",
  },
  {
    name: 'a Disney Princess',
    age: '18',
    website: 'princecharming.com',
    email: 'prince@something.com',
    location: 'a faraway land',
    wish:
      "The website you made for Prince Charming. I'd like a blog where I can write stories beauty, fashion and ruthless self defence techniques. I would also like to show my instagram feed on the website.",
  },
]

const Copyright = styled.div`
  text-align: center;
  font-size: ${rhythm(1 / 2)};
  span {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: block;
  }
  p {
    margin-bottom: ${rhythm(1 / 3)};
  }
  a {
    color: ${colors.orange};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

class FooterWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = { character: this.randomCharacter() }
  }
  randomCharacter = oldCharacterName => {
    let newChar = characters[Math.floor(Math.random() * characters.length)]
    if (newChar.name === oldCharacterName) {
      newChar = this.randomCharacter()
    }
    return newChar
  }
  render() {
    const { character } = this.state
    return (
      <Footer
        {...this.props}
        color="white"
        background={gradients.darkBlue}
        css={css`
          padding: 0;
          position: fixed;
          z-index: 5;
          width: 100%;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: space-between; //flex-end;
          height: 100vh;
          min-height: 500px;
          overflow: scroll;
          &:before {
            content: ' ';
          }
        `}
      >
        <Container>
          <NetlifyForm
            name="funny-form"
            css={css`
              max-width: 750px;
              margin: 0 auto;
              padding: ${rhythm(3)} 0;
              text-align: right;
              h2,
              p {
                text-align: center;
              }
              p {
                margin: 0;
              }
            `}
            buttonStyle={css`
              margin: 0;
              background: ${colors.orange};
            `}
          >
            <h2>
              Hi, my name is{' '}
              <Input
                name="name"
                type="text"
                required
                placeholder={character.name}
              />
              .
            </h2>
            <p>
              I am <Input name="age" type="text" placeholder={character.age} />{' '}
              years old. This is my website:{' '}
              <Input
                name="website"
                type="text"
                placeholder={character.website}
              />
              . You can reach me via{' '}
              <Input
                name="email"
                required
                type="email"
                placeholder={character.email}
              />
              . I would like to get in touch to talk to you about:
              <br />
              <Input
                required
                name="message"
                type="textarea"
                placeholder={character.wish}
              />
            </p>
            <Button
              background={colors.lightBlue}
              type="reset"
              onClick={e => {
                this.setState({
                  character: this.randomCharacter(character.name),
                })
              }}
            >
              don&apos;t click me!
            </Button>
          </NetlifyForm>
        </Container>
        <Copyright>
          {/* <p>
            Do you think are you as funny as I think I am and want to help me
            out?
            <br />
            <a href="google.com" rel="noopener noreferrer">
              submit
            </a>{' '}
            a new placehorder character to this (cool?) form!
          </p> */}
          <p>
            <GatsbyLink to="/contact">other contact options</GatsbyLink>
          </p>
          <span>
            &copy; WEBhart - {new Date().getFullYear()} | made with{' '}
            <OutboundLink
              rel="noopener"
              href="https://www.gatsbyjs.org"
              target="_blank"
            >
              Gatsby
            </OutboundLink>{' '}
            | design by{' '}
            <OutboundLink rel="noopener" href="//lucifer.be" target="_blank">
              lucifer
            </OutboundLink>
          </span>
        </Copyright>
      </Footer>
    )
  }
}
export default FooterWrapper
